<template>
  <main class="footer-bg pa-8 pa-md-15">
    <v-row class="mx-0 px-0">
      <VCol cols="12" md="6" align-self="center">
        <p class="text-h4 text-primary py-0 my-0 font-weight-bold">
          Your on-time delivery experience begins here
        </p>
        <p class="text-h6 text-white font-weight-medium">
          Talk with us to leverage accurate data of arrival predictions.
        </p>
      </VCol>
      <VCol cols="12" md="6">
        <p class="text-h4 text-white font-weight-medium text-left">
          Talk to an expert
        </p>
        <div>
          <v-form
            ref="form"
            validate-on="lazy input"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-row class="mx-0 px-0 py-3">
              <VCol cols="12" md="6">
                <VTextField
                  v-model="firstName"
                  label="First Name"
                  color="primary"
                  density="compact"
                  :rules="[(v) => !!v || 'First Name is required']"
                  required
                  variant="underlined"
                  class="text-white"
                />
              </VCol>
              <VCol cols="12" md="6">
                <VTextField
                  v-model="lastName"
                  label="Last Name"
                  :rules="[(v) => !!v || 'Last Name is required']"
                  required
                  class="text-white"
                  color="primary"
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12">
                <VTextField
                  v-model="companyEmail"
                  label="Company Email"
                  :rules="[(v) => !!v || 'Company Email is required']"
                  required
                  class="text-white"
                  color="primary"
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12" md="6">
                <VTextField
                  v-model="companyName"
                  :rules="[(v) => !!v || 'Company Name is required']"
                  required
                  label="Company Name"
                  class="text-white"
                  color="primary"
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12" md="6">
                <VTextField
                  v-model="companyCountry"
                  :rules="[(v) => !!v || 'Company Country is required']"
                  required
                  label="Company Country"
                  class="text-white"
                  color="primary"
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12">
                <VTextField
                  v-model="jobTitle"
                  label="Job Title"
                  :rules="[(v) => !!v || 'Job Title is required']"
                  required
                  color="primary"
                  class="text-white"
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12">
                <VTextField
                  v-model="help"
                  label="How can we help?"
                  color="primary"
                  class="text-white"
                  :rules="[(v) => !!v || 'Help Text is required']"
                  required
                  variant="underlined"
                  density="compact"
                />
              </VCol>
              <VCol cols="12">
                <VCheckbox
                  label="By submitting this form, you agree to receive promotional and marketing communications from Parcel Dispatch Logistics, in accordance to our privacy and cookies policy."
                  v-model="terms"
                  color="primary"
                  :rules="[(v) => !!v || 'Must Agree First']"
                  required
                  class="text-white text-left"
                  false-icon="fa-square"
                ></VCheckbox>
              </VCol>
              <VCol cols="12">
                <VBtn color="primary" variant="flat" block @click="onSubmit"
                  >Submit</VBtn
                >
              </VCol>
            </v-row>
          </v-form>
        </div>
      </VCol>
    </v-row>
  </main>
</template>
<script setup>
import userflow from "@/store/userflow";
import { ref } from "vue";
import emailjs from "@emailjs/browser";

const valid = ref(true);
// form data
const firstName = ref("");
const lastName = ref("");
const companyEmail = ref("");
const companyName = ref("");
const companyCountry = ref("");
const jobTitle = ref("");
const help = ref("");
const terms = ref(false);

const onSubmit = () => {
  const { dispatch } = userflow;

  const payload = {
    firstName: firstName.value,
    lastName: lastName.value,
    companyEmail: companyEmail.value,
    companyCountry: companyCountry.value,
    companyName: companyName.value,
    jobTitle: jobTitle.value,
    help: help.value,
    terms: terms.value,
  };
  console.log(payload);
  if (valid.value) {
    dispatch("initAlert", {
      is: true,
      message: "Form Submitted Successfully",
      type: "success",
    });

    emailjs.send(
      "service_pal5aj2",
      "template_vecunfw",
      {
        name: `${firstName.value} ${lastName.value}`,
        companyEmail: companyEmail.value,
        companyCountry: companyCountry.value,
        jobTitle: jobTitle.value,
        companyName: companyName.value,
        help: help.value,
      },
      "XPaChlCw5xgBVjOrU"
    );

    firstName.value = " ";
    lastName.value = " ";
    companyEmail.value = " ";
    companyCountry.value = " ";
    companyName.value = " ";
    jobTitle.value = " ";
    help.value = " ";
    terms.value = " ";
  }
};
</script>
<style>
.footer-bg {
  background-image: url(https://images.ctfassets.net/s384oqk42kco/2Qb6RS1DZeUzU4sQPu2UUk/fa2f03dbde179bb8754f1e01527dfea8/leads_form_background_image.webp);
  background-position: center;
  background-size: cover;
}
</style>
