<template>
  <div></div>
</template>

<script>
/* eslint-disable */
export default {
  name: "SmartsuppChat",
  mounted() {
    const smartsuppKey = "a576901db09b5fc30a9667b8e2645b3f27d18ff0"; // Replace with your key
    if (!window.smartsupp) {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.charset = "utf-8";
      s.src = "https://www.smartsuppchat.com/loader.js?";
      s.onload = () => {
        window._smartsupp = window._smartsupp || {};
        window._smartsupp.key = smartsuppKey;
        window.smartsupp ||
          (function (d) {
            const o = (smartsupp = function () {
              o._.push(arguments);
            });
            o._ = [];
          })(document);
      };
      document.body.appendChild(s);
    }

    //   var _smartsupp = _smartsupp || {};
    // _smartsupp.key = "a576901db09b5fc30a9667b8e2645b3f27d18ff0";
    // window.smartsupp ||
    //   (function (d) {
    //     var s,
    //       c,
    //       o = (smartsupp = function () {
    //         o._.push(arguments);
    //       });
    //     o._ = [];
    //     s = d.getElementsByTagName("script")[0];
    //     c = d.createElement("script");
    //     c.type = "text/javascript";
    //     c.charset = "utf-8";
    //     c.async = true;
    //     c.src = "https://www.smartsuppchat.com/loader.js?";
    //     s.parentNode.insertBefore(c, s);
    //   })(document);
  },
};
</script>
